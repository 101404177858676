.result-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    flex-direction: column; 
  }
  
  .result-div {
    border: 4px solid #67bff1;
    padding: 20px;
    margin-top: 20px; /* Add margin between divs */
  }

  .Container {
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }


   .centered-table {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .centered-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .aligned-typography {
    display: flex;
    justify-content: space-between;
  }










  




